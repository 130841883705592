import { render, staticRenderFns } from "./Venancio-Aires.vue?vue&type=template&id=41ba57d8&scoped=true&"
import script from "./Venancio-Aires.vue?vue&type=script&lang=js&"
export * from "./Venancio-Aires.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41ba57d8",
  null
  
)

export default component.exports